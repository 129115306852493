import { useEffect, useMemo, useState } from 'react';
import { getVerificationMethod, IApplicant, IReport } from '../../../models/report';
import { useDispatch } from 'react-redux';
import { Box, Chip, Link, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import palette from '../../../ui/palette';
import { sendApplicantInvite, updateApplicant } from '../../../services/application.service';
import { notify } from '../../../store/ui/actions';
import moment from 'moment';
import { BlobProvider } from '@react-pdf/renderer';
import { PDFReport } from '../../../components/pdf-report';

export default function ReportDetailContentNew({ application, refresh }: { application: IReport; refresh: any }) {
  const [selectedApplicant, setSelectedApplicant] = useState<IApplicant | null>(null);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [activeReport, setActiveReport] = useState<any>(null);
  const [allReports, setAllReports] = useState<any[]>([]);
  const [activeDoc, setActiveDoc] = useState<any>(null);

  const applicants = useMemo(() => {
    const head = application.applicants.filter(a => a.role === 'Head of Household')!!;
    const guarantor = application.applicants.filter(a => a.role === 'Guarantor');
    const cosigner = application.applicants.filter(a => a.role === 'Non-Resident Co-sign');
    const others = application.applicants.filter(a => ['Guarantor', 'Head of Household', 'Non-Resident Co-sign', 'Family Member - Minor'].indexOf(a.role) < 0);
    let arr: any[] = [];
    if (head && head.length) arr = arr.concat(head);
    if (others && others.length) arr = arr.concat(others);
    if (cosigner && cosigner.length) arr = arr.concat(cosigner);
    if (guarantor && guarantor.length) arr = arr.concat(guarantor);
    return arr;
  }, [application.applicants]);

  const setAccepted = async (item: any, accepted: boolean, index: number) => {
    // eslint-disable-next-line no-restricted-globals
    const answer = confirm(
      'This document is a scanned version. To ensure accuracy, please be aware that changes might have been made before it was scanned. If the authenticity of the document is critical, please consider obtaining the original. \n \nAre you sure you want to accept this document?'
    );
    if (answer) {
      const incomeHistories = application.applicants.find(a => a._id === activeReport.id)?.income_history!!;
      incomeHistories[activeReport.index].items[index].accepted = accepted;
      if (item.typeR === 'Paystub') {
        const paystubIncome = item.income ?? 0;
        incomeHistories[activeReport.index].avgGrossPay = paystubIncome;
      }
      if (item.typeR === 'Offer Letter') {
        incomeHistories[activeReport.index].total = item.annual_income;
        incomeHistories[activeReport.index].avgGrossPay = (item.annual_income / 12.0).toFixed(2);
      }
      if (item.typeR === 'Bank Statement') {
        let bankStatementIncome = 0;
        const transactions = item.data.transactions ? item.data.transactions : item.data.income_transactions ?? [];
        transactions.forEach((transaction: any) => {
          bankStatementIncome = bankStatementIncome + transaction.amount;
        });
        incomeHistories[activeReport.index].total = bankStatementIncome.toFixed(2);
      }
      await updateApplicant(activeReport.id, { 'applicants.$.income_history': incomeHistories });
      refresh();
    }
  };

  useEffect(() => {
    if (!selectedApplicant && applicants?.length > 0) {
      setSelectedApplicant(applicants[0]);
    } else if (selectedApplicant) {
      setSelectedApplicant(applicants.find(a => a._id === selectedApplicant._id)!!);
    }
    applicants.forEach(a => {
      a.income_history.forEach((ih: any, index: number) => {
        ih.name = a.name;
        ih.verification_date = a.verification_attempts[index];
        ih.verification_method = a.verification_method[index];
        ih.id = a._id;
        ih.index = index;
        ih.changeAllowed = index === a.income_history.length - 1;
      });
    });
    setAllReports(applicants.map(a => a.income_history).flat());
    setActiveReport(
      applicants.map(a => a.income_history).flat().length
        ? applicants.map(a => a.income_history).flat()[applicants.map(a => a.income_history).flat().length - 1]
        : null
    );
    setActiveTab(applicants.map(a => a.income_history).flat().length ? applicants.map(a => a.income_history).flat().length - 1 : 0);
  }, [applicants]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 3 }}>
        <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column', width: '50%' }}>
          <Stack gap={2} sx={{ border: 1, borderColor: palette.grey['50'], borderRadius: 2, p: 3, color: palette.text.secondary }}>
            <Stack flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
              <Typography variant="h5" color="text.primary" fontWeight={500}>
                Applicants Information
              </Typography>
            </Stack>
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicants.map((applicant, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body1">{applicant.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{applicant.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ cursor: 'pointer', color: '#6078EA' }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://${window.location.host.replace('dashboard', 'app')}/${application.property_code}/verification?token=${applicant.token}`
                            );
                            dispatch(
                              notify({
                                severity: 'success',
                                open: true,
                                message: 'Link Copied',
                              })
                            );
                          }}
                        >
                          Copy Link
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ cursor: 'pointer', color: '#6078EA' }}
                          onClick={async () => {
                            await sendApplicantInvite(application._id, applicant._id);
                            dispatch(
                              notify({
                                severity: 'success',
                                open: true,
                                message: 'Invite Sent',
                              })
                            );
                          }}
                        >
                          Resend Invite
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack flexDirection={'row'} alignItems="center" justifyContent="space-between">
              <Typography variant="h5" color="text.primary">
                Property:{' '}
              </Typography>
              <Typography>{application.property_name}</Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems="center" justifyContent="space-between">
              <Typography variant="h5" color="text.primary">
                Unit:{' '}
              </Typography>
              <Typography>{application.unit}</Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems="center" justifyContent="space-between">
              <Typography variant="h5" color="text.primary">
                Total Monthly Income:{' '}
              </Typography>
              <Typography>${application.total_monthly_income}</Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems="center" justifyContent="space-between">
              <Typography variant="h5" color="text.primary">
                Monthly Rent:{' '}
              </Typography>
              <Typography>${application.monthly_rent}</Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems="center" justifyContent="space-between">
              <Typography variant="h5" color="text.primary">
                Income Level:{' '}
              </Typography>
              <Typography>{application.income_level}</Typography>
            </Stack>
          </Stack>
          <Stack gap={2} sx={{ border: 1, borderColor: palette.grey['50'], borderRadius: 2, p: 3, color: palette.text.secondary }}>
            <Stack flexDirection="row" alignItems="center" gap={2} sx={{ mb: 1 }}>
              <Typography variant="h5" color="text.primary" fontWeight={500}>
                Verifications Completed
              </Typography>
              <Typography>(** denotes latest/last verification)</Typography>
            </Stack>
            <Stack gap={1}>
              <Tabs
                value={activeTab}
                onChange={(_e, value) => {
                  setActiveTab(value);
                  setActiveReport(allReports[value]);
                }}
              >
                {allReports.map((report, index) => (
                  <Tab
                    key={index}
                    value={index}
                    label={'Report ' + (index + 1) + (report.changeAllowed ? '**' : '')}
                    sx={{ borderBottom: '1px solid #D4D7DC' }}
                  />
                ))}
              </Tabs>
              {activeReport && (
                <Stack gap={2} sx={{ border: 1, borderColor: palette.grey['50'], borderRadius: 2, p: 3, color: palette.text.secondary }}>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1"></Typography>
                    <BlobProvider
                      document={<PDFReport applicant={applicants.find(a => a._id === activeReport.id)} application={application} idx={activeReport.index} />}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          'Loading document...'
                        ) : (
                          <Link href={url!!} target="_blank">
                            <Typography variant="body1">View Report</Typography>
                          </Link>
                        )
                      }
                    </BlobProvider>
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">Applicant Name</Typography>
                    <Typography variant="body1">{activeReport.name}</Typography>
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">Method Used</Typography>
                    <Typography variant="body1">{getVerificationMethod(activeReport.verification_method)}</Typography>
                  </Stack>
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">Verification Date</Typography>
                    <Typography variant="body1">{moment(activeReport.verification_date).format('MM/DD/YYYY')}</Typography>
                  </Stack>
                  {activeReport.verification_method === 'Document Upload' && (
                    <>
                      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1">Invalid Docs Count</Typography>
                        <Typography variant="body1">{activeReport.errorDocs}</Typography>
                      </Stack>
                      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1">Scanned Docs Count</Typography>
                        <Typography variant="body1">{activeReport.scannedCount}</Typography>
                      </Stack>
                    </>
                  )}
                  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">Accepted Monthly Income</Typography>
                    <Typography variant="body1">
                      $
                      {activeReport.verification_method === 'Document Upload'
                        ? activeReport.avgGrossPay ?? ((activeReport.total ?? 0) / 12).toFixed(2)
                        : activeReport.avgGrossPay
                          ? activeReport.avgGrossPay
                          : (activeReport.total / 3).toFixed(2)}
                    </Typography>
                  </Stack>
                  {activeReport.verification_method === 'Document Upload' ? (
                    <>
                      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1">Documents Uploaded</Typography>
                      </Stack>
                      <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}>
                        <Table aria-label="simple table">
                          <TableBody>
                            {activeReport.items?.map((item: any, index: number) => (
                              <TableRow>
                                <TableCell>
                                  <Typography variant="body1">{item.typeR}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">${item.income ?? 0}</Typography>
                                </TableCell>
                                <TableCell>
                                  {/* {activeReport.changeAllowed && !item.accepted && item.data?.isScanned ? (
                                    <Chip label={'Accept'} color={'success'} sx={{ cursor: 'pointer' }} onClick={() => setAccepted(item, true, index)} />
                                  ) : ( */}
                                  <Typography>{item.accepted ? 'Accepted' : 'Not Accepted'}</Typography>
                                  {/* )} */}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    label={'Preview'}
                                    color={item.accepted ? 'success' : item.data?.isScanned ? 'warning' : 'error'}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => setActiveDoc(item)}
                                  />
                                </TableCell>
                                <TableCell>
                                  {!item.accepted && item.data?.isScanned ? (
                                    <Typography>Scanned Document</Typography>
                                  ) : !item.accepted ? (
                                    <Typography>Fraud / Invalid Document</Typography>
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <></>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
        {activeDoc && (
          <Box sx={{ width: '50%' }}>
            <object data={activeDoc.file!!} width={'100%'} height={'100%'}></object>
          </Box>
        )}
      </Box>
    </>
  );
}
